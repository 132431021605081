<template>
    <v-container grid-list-xl fluid class="d-print-flex">
        <v-layout wrap v-if="!showMode" class="d-print-none">
            <v-flex xs12>
                <h1>Relatório de Carregamentos</h1>
                <v-divider class="mb-4" />
            </v-flex>

            <v-flex xs12>
                <CondominiumList :value="selectedCondominium" :multiple="false" @change="changeCondominium" />
            </v-flex>
            <v-flex xs12>
                <DateInterval onlyPast :fitDay="fitDay" @onChange="dates = $event" />
            </v-flex>
            <v-flex xs12>
                <v-text-field v-model="price" :rules="[rules.money]" label="Tarifa do kWh" v-mask="['#.##', '##.##']" return-masked-value prefix="R$" type="number" />
            </v-flex>

            <v-flex xs12 text-center>
                <v-btn :disabled="processing" color="success" @click="get">Gerar Relatório</v-btn>
            </v-flex>
        </v-layout>

        <v-layout wrap class="d-print-none" v-else>
            <v-flex xs12 sm4 text-center>
                <v-btn @click="createPDF" color="success" style="width: 100%"><v-icon left>print</v-icon>Download em PDF</v-btn>
            </v-flex>
            <v-flex xs12 sm4 text-center>
                <DownloadCsv :data="csv" name="relatorio.csv" :labels="csvLabels" delimiter=";">
                    <v-btn color="success" style="width: 100%"><v-icon left dark> download </v-icon>Download em CSV</v-btn>
                </DownloadCsv>
            </v-flex>
            <v-flex xs12 sm4 text-center>
                <v-btn @click="showMode = false" color="error" class="mr-4" style="width: 100%"><v-icon left>close</v-icon>Fechar Relatório</v-btn>
            </v-flex>
        </v-layout>

        <v-progress-linear v-if="processing" indeterminate />

        <v-layout wrap v-if="showMode" class="d-print-flex">
            <v-flex xs12 sm8>
                <Incharge></Incharge>
            </v-flex>
            <v-flex xs12 sm4>
                <h4>RELATÓRIO GERENCIAL DE SERVIÇOS</h4>
            </v-flex>

            <v-flex xs12 v-if="condominium">
                <v-flex xs12 py-0 v-if="condominium.name">
                    <strong>Nome:</strong>
                    {{ this.condominium.name }}
                </v-flex>
                <v-flex xs12 py-0 v-if="condominium.cnpj">
                    <strong>CNPJ:</strong>
                    {{ this.condominium.cnpj }}
                </v-flex>
                <v-flex xs12 py-0 v-if="condominium.address">
                    <strong>Endereço:</strong>
                    {{ this.condominium.address }}
                </v-flex>
                <v-flex xs12 py-0 v-if="condominium.cep">
                    <strong>CEP:</strong>
                    {{ this.condominium.cep }}
                </v-flex>
                <v-flex xs12 py-0 v-if="condominium.city">
                    <strong>Cidade / UF:</strong>
                    {{ this.condominium.city }}
                </v-flex>
            </v-flex>

            <v-flex xs12 offset-sm4 sm8>
                <v-layout wrap>
                    <v-flex xs12 sm6 class="pa-0 pb-1 pr-1">
                        <v-card color="primary">
                            <v-card-text class="white--text py-1">
                                <div class="headline mb-1">
                                    <v-icon dark>battery_charging_full</v-icon>
                                    {{ consumptionTotal }} kWh
                                </div>
                                Energia consumida
                            </v-card-text>
                        </v-card>
                    </v-flex>
                    <v-flex xs12 sm6 class="pa-0 pb-1">
                        <v-card color="primary">
                            <v-card-text class="white--text py-1">
                                <div class="headline mb-1">
                                    <v-icon dark>access_time</v-icon>
                                    {{ printRange }}
                                </div>
                                Periodo
                            </v-card-text>
                        </v-card>
                    </v-flex>
                    <v-flex xs12 sm6 class="pa-0 pb-1 pr-1">
                        <v-card color="primary" v-if="price > 0">
                            <v-card-text class="white--text py-1">
                                <div class="headline mb-1">
                                    R$ {{ payToCondominium }}
                                </div>
                                Valor Total
                            </v-card-text>
                        </v-card>
                    </v-flex>
                    <v-flex xs12 sm6 class="pa-0 pb-1">
                        <v-card color="primary">
                            <v-card-text class="white--text py-1">
                                <div class="headline mb-1">
                                    <v-icon dark>access_time</v-icon>
                                    {{ printInterval }}
                                </div>
                                Total de dias
                            </v-card-text>
                        </v-card>
                    </v-flex>
                    <v-flex xs12 class="pa-0 pb-1" v-if="payByPlataform > 0">
                        <v-card color="warning">
                            <v-card-text class="secondary--text py-1">
                                <div class="headline mb-1">
                                    <v-icon color="secondary">paid</v-icon>
                                    R$ {{ payByPlataform }}
                                </div>
                                Pago pela plataforma
                            </v-card-text>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-flex>

            <v-flex xs12 v-if="items.length > 0" class="pa-0 px-1 pt-2">
                <v-data-table :headers="headers" :items="items" :items-per-page="-1" class="elevation-2 table-red" hide-default-footer :loading="!items" dark>
                    <template v-slot:[`item.consumption`]="{ item }">{{ item.consumption }} kWh</template>
                    <template v-slot:[`item.price`]="{ item }">R$ {{ item.paymentValue > 0 ? item.paymentValue : priceCalc(item.consumption) }}</template>
                </v-data-table>
            </v-flex>

            <v-flex xs12>
                <v-img :src="require('@/assets/images/footerReport.png')" />
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import DownloadCsv from "vue-json-csv";
import dateTime from "@/helpers/DateTime";
import CondominiumList from "@/components/shared/CondominiumList";
import DateInterval from "@/components/core/DateInterval";
import Incharge from "@/components/shared/Incharge";
import { mask } from "vue-the-mask";
import rules from "@/helpers/rules";

import condominiumReportPDF from "@/pdf/condominiumReport";

export default {
    directives: { mask },

    components: {
        Incharge,
        DateInterval,
        CondominiumList,
        DownloadCsv,
    },

    data() {
        return {
            rules,
            fitDay: 20,
            condominiumReportPDF,
            processing: false,
            showMode: false,
            dates: [],
            condominiuns: null,
            price: null,
            dateTime,
            selectedCondominium: null,
            items: [],
            condominium: null,
            headers: null,
            csvLabels: {
                block: "Bloco",
                apartment: "Apartamento",
                consumption: "Consumo(kWh)",
                price: "Preço",
            },
        };
    },

    methods: {
        priceCalc(consumption) {
            return this.price > 0 ? Number.parseFloat(Number.parseFloat(consumption) * Number.parseFloat(this.price)).toFixed(2) : 0;
        },
        changeCondominium(newCondominium) {
            this.selectedCondominium = newCondominium.id;
            this.fitDay = newCondominium.fitDay;
        },
        createPDF() {
            let doc = condominiumReportPDF.createCondominiumReport(this.condominium, this.printRange, this.printInterval, this.items, this.consumptionTotal, this.price);

            // doc.output("report.pdf");
            window.open(doc.output("bloburl"));
        },
        get() {
            if (this.selectedCondominium) {
                if (!this.processing) {
                    this.processing = true;

                    this.items = [];
                    this.condominium = null;

                    let query = this.dates.length == 2 ? `?dateFrom=${this.dates[0]}&dateTo=${this.dates[1]}` : `?dateFrom=${this.dates[0]}&dateTo=${this.dates[0]}`;

                    this.$http
                        .get(`api/v2/condominium/charge/${this.selectedCondominium}${query}`)
                        .then((result) => {
                            if (result && result.history) {
                                this.items = result.history;
                                this.headers = [
                                    { text: "Bloco", value: "block", align: "left" },
                                    { text: "Apartamento", value: "apartment", align: "left" },
                                    { text: "Consumo", value: "consumption", align: "center" },
                                    // { text: "Usuário", value: "user", align: "left" },
                                ];
                                if (this.price > 0 || this.payByPlataform > 0) {
                                    this.headers.push({ text: "Preço", value: "price", align: "center" });
                                }
                                this.items.sort((a, b) => (a.apartment === b.apartment ? 0 : parseInt(a.apartment) > parseInt(b.apartment) ? 1 : -1));

                                this.items.sort((a, b) => (a.block === b.block ? 0 : a.block > b.block ? 1 : -1));

                                this.condominium = result.condominium;
                                this.showMode = true;
                            } else {
                                this.$eventHub.$emit("msgError", "Nenhuma informação localizada.");
                            }
                            this.processing = false;
                        })
                        .catch(() => {
                            this.processing = false;
                        });
                }
            } else {
                this.$eventHub.$emit("msgError", "Selecione o condominio.");
            }
        },
    },

    computed: {
        csv() {
            if (this.items.length > 0) {
                let ret = this.items.map((i) => {
                    return (this.price > 0) ? {
                        block: i.block,
                        apartment: i.apartment,
                        consumption: i.consumption,
                        price: this.priceCalc(i.consumption),
                    } : {
                        block: i.block,
                        apartment: i.apartment,
                        consumption: i.consumption,
                    };
                });
                return ret;
            }

            return null;
        },
        consumptionTotal() {
            return Number.parseFloat(
                this.items.reduce(function (sum, i) {
                    return sum + i.consumption;
                }, 0)
            ).toFixed(2);
        },
        consumptionNotPayed() {
            return Number.parseFloat(
                this.items.reduce(function (sum, i) {
                    return i.paymentValue == 0 ? (sum + i.consumption) : sum;
                }, 0)
            ).toFixed(2);
        },
        payToCondominium() {
            return this.price > 0 ? Number.parseFloat(this.consumptionNotPayed * this.price).toFixed(2) : null;
        },
        payByPlataform() {
            return Number.parseFloat(
                this.items.reduce(function (sum, i) {
                    return sum + i.paymentValue;
                }, 0)
            ).toFixed(2);
        },
        printRange() {
            return `${this.dateTime.tranformDate(this.dates[0])} a ${this.dateTime.tranformDate(this.dates[1])}`;
        },
        printInterval() {
            return `${this.dateTime.getInterval(this.dates[0], this.dates[1])} DIAS`;
        },
    },
};
</script>

<style>
.table-red tbody tr:nth-of-type(even) {
    background-color: #ffc3c3;
}

.table-red tbody tr:nth-of-type(odd) {
    background-color: #ee8d8d;
}

.table-red .v-data-table-header {
    background-color: var(--v-primary-base);
    color: white;
}

.table-red .theme--light.v-data-table thead tr th {
    color: white;
}
</style>